import axiosInstance from "../../Api/Api";
import axiosInstancePublic from "../../Api/Api_public";

export default class AuthenticationService {
      /**
     * update user password
     * @param formData 
     * @returns 
     */
  async passwordUpdate(formData: FormData) {
    try {
        const response= await axiosInstancePublic.put("auth/password/reset", formData);
        return response;
    } catch (error) {
        
    }
  }

    /**
     * Reset user password
     * @param formData 
     * @returns 
     */
    async resetPassword(formData: FormData) {
        try {
            const response = await axiosInstancePublic.put("auth/setEmail/confirmationCode", formData);
            return response;
        } catch (error) {

        }
    }
    /**
     * User login with username and password
     * @param formData 
     * @returns 
     */
    async login(formData: FormData) {
        try {
            const response = await axiosInstancePublic.post("auth/login", formData);
            return response;
        } catch (error) {

        }

    }



    /**
     * User sign up
     * @param formData 
     * @returns 
     */
    async signUp(formData: FormData) {
        try {
            const response = await axiosInstancePublic.post("auth/registration", formData);
            return response;
        } catch (error) {
   
            return Promise.reject(error);
        }

    }


    /**
     * Verify user account
     * @param userID 
     * @param emailConfirmationCode 
     * @returns 
     */
    async verifyAccount(userID: string, emailConfirmationCode: string) {
        try {

            const response = await axiosInstancePublic.get(`auth/email/confirmation/${userID}/${emailConfirmationCode}`);
            return response;
        } catch (error) {

        }
    }


    /**
     * Complete user sign up step two
     * @param formData 
     * @returns 
     */
    async completeSignUP(formData: FormData) {
        try {
            const response = await axiosInstance.post("user/firstTimeLogin", formData);
            return response;
        } catch (error) {

        }
    }



    /**
     *  verify  username availability 
     * @param newUsername 
     * @returns 
     */
    async checkUsernameAvailability(newUsername: string) {
        try {
            const response = await axiosInstancePublic.get(`auth/return/user/given/username/${newUsername}`);
            return response;
        } catch (error) {

        }
    }

    /**
     * get countries
     * @returns list of countries
     */
    async getCountries() {
        try {
            const response = await axiosInstancePublic.get(`/auth/countries`);
            return response;
        } catch (error) {

        }

    }

    /**
     * get titles
     * @returns titles
     */
    async getTitles() {
        try {

            const response = await axiosInstance.get(`mst/titles`);
            return response;
        } catch (error) {

        }

    }


    /**
     * Get occutation list
     * @returns  occutation list
     */
    async getOccupations() {
        try {
            const response = await axiosInstancePublic.get(`auth/Occupations`);
            return response;
        } catch (error) {

        }
    }


    /**
     * marital status
     * @returns marital status list
     */
    async getMaritalStatus() {

        try {
            const response = await axiosInstance.get(`mst/maritalStatus`);
            return response;
        } catch (error) {

        }
    }


    /**
     * Send contact us email
     * @param formData 
     * @returns 
     */
    async sendContactUsMessage(formData: FormData) {

        try {
            const response = await axiosInstance.post(`/mail`, formData);
            return response;
        } catch (error) {

        }
    }


    /**
     * search user using email address
     * @param formData 
     */
    async searchUserUsingEmailAddress(formData: FormData) {
        try {
            const response= await axiosInstance.post(`user/search`,formData);
            return response;
        } catch (error) {
            
        }
        
    }


/**
    * Retrieve user details
    * @returns user details 
    */
 async getUserDetails() {
    try {
        const response= await axiosInstance.get(`user/details`);
        return response;
    } catch (error) {
        
    }
    
}

}