import axios from "axios";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import AppUrls from '../Utils/AppUrls';
const MySwal = withReactContent(Swal);

let config = {
  "Content-Type": "multipart/form-data",
  Accept: "application/json"
};

const axiosInstancePublic = axios.create({
  baseURL: AppUrls.AppApiBaseUrls,
  config
});


axiosInstancePublic.interceptors.response.use(
  (res) => {
    return res;
  },
  (err) => {
   
    if (err.response.status === 500) {
      MySwal.fire({
        title: "Something went wrong.",
        text: `An error has occured, please try again.`,
        icon: "error",

        confirmButtonColor: "#3085d6",

        confirmButtonText: "Retry",
        allowOutsideClick: false,
        backdrop: `linear-gradient(  to right,
          #2a0845,
          #6441a5)`
      }).then((result) => {
        if (result.isConfirmed) {
        }
      });
    }
    return Promise.reject(err.response.data.errors);
  }
);

export default axiosInstancePublic;