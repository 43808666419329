import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../App/store";
import AuthenticationService from "../../Services/AuthenticationService/AuthenticationService";
import PrivateMessageService from "../../Services/PrivateMessageService/PrivateMessageService";
import IPrivateMessageSenders from "../../Types/IPrivateMessageSenders";
import IProfile from "../../Types/IProfile";

export interface IPrivateMessageSenderState {
    privateMessageSenders: IPrivateMessageSenders[];
    status: 'idle' | 'loading' | 'succeeded' | 'failed';
    error: string;
}

// const privateMessageSenders: IPrivateMessageSenders = {
//     from_ID: "",
//     from_name: "",
//     from_surname: "",
//     from_profileImage: "",
//     totalSent: 0
// }
const initialState: IPrivateMessageSenderState = {
    privateMessageSenders: [],
    status: "idle",
    error: ""
}


const _privateMessageService = new PrivateMessageService(); 

export const getPrivateMessagesSenders = createAsyncThunk('privatemessage/fetchprivateMessageSenders', async () => {
    try {

        const response = await _privateMessageService.getSendersPrivateMessages(); 
        return response?.data?.list;

    } catch (error: any) {
        return error.message
    }
});



const privateMessageSlice= createSlice({
    name: "user",
    initialState,
    reducers: {
        
    },
    extraReducers: (builder) => {
        builder
            .addCase(getPrivateMessagesSenders.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(getPrivateMessagesSenders.fulfilled, (state, action) => {
                state.status = 'idle';
                state.privateMessageSenders = action.payload;
                
            })
            .addCase(getPrivateMessagesSenders.rejected, (state, action) => {
                // state.status = 'failed',
                // state.error = action.error.message as string;
            });

    }
   
})


export const selectPrivateMessagesSenders=(state:RootState)=> state.privateMessage.privateMessageSenders;
export const RequestUserStatus=(state:RootState)=> state.user.status;
export const RequestUserError=(state:RootState)=> state.user.error;
export default privateMessageSlice.reducer;