import axiosInstance from "../../Api/Api";

export default class PrivateMessageService {
    /**
     * send private message to user
     * @param formData 
     * @returns 
     */
    async sendPrivateMessage(formData: FormData) {
        try {
            const response = await axiosInstance.post(`privateMessage`, formData);
            return response;
        } catch (error) {

        }
    }


    /**
     * get list of private message senders
     * @returns 
     */
    async getSendersPrivateMessages() {
        try {
            const response = await axiosInstance.get(`privateMessage/senders/list`);
            return response;
        } catch (error) {

        }
    }
    /**
     * get private messages
     * @param userId 
     * @returns 
     */
    async getUsersPrivateMessages(userId: string) {
        try {
            const response = await axiosInstance.get(`privateMessage/${userId}`);
            return response;
        } catch (error) {

        }
    }


    /**
     * Mark private message as read
     * @param messageId 
     * @returns 
     */
    async markPrivateMessageAsRead(messageId: string) {
        try {
            const response = await axiosInstance.put(`privateMessage/${messageId}`);
            return response;
        } catch (error) {

        }
    }



    /**
     * Search user by name 
     * @param name 
     * @returns list of users
     */
    async searchUserByName(name:string) {
        try {
            const response= await axiosInstance.get(`/user/search/userBy/${name}`);
            return response;
        } catch (error) {
            
        }
        
    }

}