import React, { Suspense } from 'react';
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { ScaleLoader } from 'react-spinners';
import '../Assets/Styles/App.scss';







const LandingPage = React.lazy(() => import('../Pages/LandingPage/LandingPage'));
const Login = React.lazy(() => import('../Pages/Auth/Login/Login'));
const SignUp = React.lazy(() => import('../Pages/Auth/SignUp/SignUp'));
const AccountVerifcation = React.lazy(() => import('../Pages/Auth/AccountVerifcation/AccountVerifcation'));
const AccountEmailVerificationMessage = React.lazy(() => import('../Pages/Auth/AccountEmailVerificationMessage/AccountEmailVerificationMessage'));
const AccountEmailVerificationRequiredMessage = React.lazy(() => import('../Pages/Auth/AccountEmailVerificationRequiredMessage/AccountEmailVerificationRequiredMessage'));
const PasswordReset = React.lazy(() => import('../Pages/Auth/PasswordReset/PasswordReset'));
const NewPassword = React.lazy(() => import('../Pages/Auth/NewPassword/NewPassword'));
const CompleteSignUp = React.lazy(() => import('../Pages/Auth/CompleteSignUp/CompleteSignUp'));
const CommunityGuidelines = React.lazy(() => import('../Pages/AlumniConnect/Legal/CommunityGuidelines/CommunityGuidelines'));
const MembershipBenefits = React.lazy(() => import('../Pages/AlumniConnect/Legal/MembersBenefit/MembersBenefit'));
const PrivacyAndDataPolicy = React.lazy(() => import('../Pages/AlumniConnect/Legal/PrivacyAndDataPolicy/PrivacyAndDataPolicy'));
const OurSolutions = React.lazy(() => import('../Pages/AlumniConnect/OurSolutions/OurSolutions'));
const OurServices = React.lazy(()=>import('../Pages/AlumniConnect/OurServices/OurServices'));
const KnowledgeCommunities=React.lazy(()=>import('../Pages/AlumniConnect/KnowledgeCommunities/KnowledgeCommunities'));
const ContactUs = React.lazy(() => import('../Pages/AlumniConnect/ContactUs/ContactUs'));
const MembersRightAndLimitations = React.lazy(() => import('../Pages/AlumniConnect/Legal/MembersRightAndLimitations/MembersRightAndLimitations'));
const UserProfile = React.lazy(() => import('../Pages/Profile/Profile'));
const Members = React.lazy(() => import('../Pages/Members/Members'));
const EditProfile = React.lazy(() => import('../Pages/Profile/EditProfile'));
const EditUserInstitution = React.lazy(() => import('../Pages/UserInstitution/EditUserInstitution'));
const NewPrivateMessage= React.lazy(()=>import('../Pages/PrivateMessage/NewPrivateMessage'));
const NewPrivateMessageWithSearch= React.lazy(()=>import('../Pages/PrivateMessage/NewPrivateMessageWithSearch'));
const ViewPrivateMessage= React.lazy(()=>import('../Pages/PrivateMessage/ViewPrivateMessage'));
const PrivateMessages= React.lazy(()=>import('../Pages/PrivateMessage/PrivateMessages'));
const SendersPrivateMessages= React.lazy(()=>import('../Pages/PrivateMessage/SendersPrivateMessages'));
const Logout = React.lazy(() => import('../Pages/Auth/Logout/Logout'));
const MainCommunityPage = React.lazy(() => import('../Pages/MainCommunityPage/MainCommunityPage'));
const AddUserInstitution = React.lazy(() => import('../Pages/UserInstitution/AddUserInstitution'));
const NewPost = React.lazy(() => import('../Pages/Posts/NewPost'));
const Posts = React.lazy(() => import('../Pages/Posts/Posts'));
const ViewPost = React.lazy(() => import('../Pages/Posts/ViewPost'));
const EditPost = React.lazy(() => import('../Pages/Posts/EditPost'));
const EditComment = React.lazy(() => import('../Pages/Comments/EditComment'));
const EventsCalendar= React.lazy(()=> import('../Pages/EventsCalendar/EventsCalendar'));
const Announcements = React.lazy(() => import('../Pages/Announcement/Announcements'));
const FilesFromPosts= React.lazy(()=> import('../Pages/FilesFromPosts/FilesFromPosts'));
const MembershipProcess= React.lazy(()=> import('../Pages/MembershipProcess/MembershipProcess'));
const Legal=React.lazy(()=>import('../Pages/AlumniConnect/Legal/Legal'));
const Resources= React.lazy(()=>import('../Pages/AlumniConnect/Resources/Resources'));
const  OurProgram= React.lazy(()=>import('../Pages/AlumniConnect/OurProgram/OurProgram'));
const Publications = React.lazy(() => import('../Pages/Publication/Publications'));
const ViewPublication=React.lazy(()=> import('../Pages/Publication/ViewPublication'));
const PageNotFound = React.lazy(() => import('../Pages/PageNotFound/PageNotFound'));


function App() {
  return (
    <div className="App">
      <Router>
        <Suspense fallback={<div className="loader-container"><ScaleLoader
          height={35} width={4} radius={2}
          margin={2} color="#7b2cbf" /></div>}>
          <Routes>
            <Route path='/' element={<LandingPage />} />
            <Route path='/login' element={<Login />} />
            <Route path='/join' element={<SignUp />} />
            <Route path='/passwordreset' element={<PasswordReset />} />
            <Route path='/newpassword/:EmailConfirmationCode' element={<NewPassword />} /> 
             <Route path='/completesignup' element={<CompleteSignUp />} />
            
            <Route path='/account/verify/:userID/:emailConfirmationCode' element={<AccountVerifcation />} />
            <Route path='/account/email/verification' element={<AccountEmailVerificationMessage />} />
            <Route path='/account/email/required' element={<AccountEmailVerificationRequiredMessage />} />
            <Route path='/feeds' element={<MainCommunityPage />} />
            <Route path='/users/profile/:userId' element={<UserProfile />} />
            <Route path='/users/editprofile/:userId' element={<EditProfile />} />
            <Route path='/users/institutions/new' element={<AddUserInstitution />} />
            <Route path='/posts/new' element={<NewPost />} />
            <Route path='/posts/:postID' element={<ViewPost />} />
            <Route path='/posts/edit/:postID' element={<EditPost />} />
            <Route path='/posts' element={<Posts />} />
            <Route path='/calendar' element={<EventsCalendar />} />
            <Route path='/announcements' element={<Announcements />} />
            <Route path='/files' element={<FilesFromPosts />} />
            <Route path='/publications' element={<Publications />} />
            <Route path='/publications/:contentId' element={<ViewPublication />} />
            <Route path='/comments/edit/:commentID' element={<EditComment />} />
            <Route path='/members/:userId' element={<Members />} />
            <Route path='/users/messages/new/' element={<NewPrivateMessageWithSearch/>}/> 
            <Route path='/users/messages/new/:userId' element={<NewPrivateMessage/>}/> 
            <Route path='/users/messages/' element={<SendersPrivateMessages/>}/>
            <Route path='/users/messages/list/:from_ID' element={<PrivateMessages/>}/>
            <Route path='/users/messages/:privateMessageID' element={<ViewPrivateMessage/>} />
            
            <Route path='/users/institutions/edit/:userInstitutionID' element={<EditUserInstitution />} />
            <Route path='/users/logout' element={<Logout />} />
            <Route path='/membership/process' element={<MembershipProcess/>}/>
            <Route path='/alumniconnect/legal/' element={<Legal/>}/>
            <Route path='/alumniconnect/resources' element={<Resources/>}/>
            <Route path='/alumniconnect/ourprogram' element={<OurProgram/>}/>
            <Route path='/alumniconnect/company/ourservices' element={<OurServices />} />
            <Route path='/alumniconnect/company/KnowledgeCommunities' element={<KnowledgeCommunities/>}/>
            <Route path='/alumniconnect/company/contactus' element={<ContactUs />} />
            <Route path='/alumniconnect/legal/guidelines' element={<CommunityGuidelines />} />
            <Route path='/alumniconnect/legal/members/benefits' element={<MembershipBenefits />} />
            <Route path='/alumniconnect/legal/members/rightsandlimitations' element={<MembersRightAndLimitations />} />
            <Route path='/alumniconnect/legal/members/privacy' element={<PrivacyAndDataPolicy />} />
            <Route path='alumniconnect/company/oursolution'element={<OurSolutions />} />
            
            <Route path="*" element={<PageNotFound />} />

          </Routes>
        </Suspense>
      </Router>


    </div>
  );
}

export default App;
