import axios from "axios";
import withReactContent from 'sweetalert2-react-content';
import Swal from 'sweetalert2';
import AppUrls from '../Utils/AppUrls'; 
const MySwal = withReactContent(Swal);

let config = {
  "Content-Type": "multipart/form-data",
  Accept: "application/json",
  
  
};




const axiosInstance = axios.create({
  baseURL: AppUrls.AppApiBaseUrls,
  config,
  headers: {
    Authorization : sessionStorage.getItem("token") 
    }
 
//

});
 
axiosInstance.interceptors.request.use(
  (req) => {
     
    return req;
  },
  (err) => {

    return Promise.reject(err);
  }
);

// For POST requests
axiosInstance.interceptors.response.use(
  (res) => {


    return res;
  },
  (err) => {

    if (err.response.status === 403) {
 
    }
    if (err.response.status === 401) {
 
    }
    if (err.response.status === 400) {


    }
    if (err.response.status === 500) {

      MySwal.fire({
        title: 'Authentication required',
        text: `Your session has expired, Login required`,
        icon: 'error',

        confirmButtonColor: '#3085d6',

        confirmButtonText: 'Login',
        allowOutsideClick: false,
        backdrop: `linear-gradient(to left, #1488cc, #2b32b2)`
      }).then((result) => {
        if (result.isConfirmed) {
          // sessionStorage.clear();
          // window.location="/login";      
        }
      });

    }

    return Promise.reject(err.response.data.errors);
  }
);

export default axiosInstance;
