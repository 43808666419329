import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../App/store";
import AuthenticationService from "../../Services/AuthenticationService/AuthenticationService";
import IProfile from "../../Types/IProfile";

export interface IuserState {
    user: IProfile;
    status: 'idle' | 'loading' | 'succeeded' | 'failed';
    error: string;
}

const userDetails: IProfile = {
    userID: "",
    name: "",
    surname: "",
    dateOfBirth: "",
    profileImage: "",
    genderID: 0,
    titleID: 0,
    languageID: 0,
    countryOfOriginID: 0,
    countryOfResidenceID: 0,
    roleID: 0,
    selfDescription: "",
    createdBy: "",
    createdDate: "",
    editedBy: "",
    editedDate: "",
    email: "",
    phonenumber: "",
    genderName: "",
    titleName: "",
    languageName: "",
    countryOfOriginName: "",
    countryOfResidenceName: "",
    roleName: "",
    occupationID: 0,
    occupationName: "",
    cityID: "",
    cityName: "",
    maritalStatusID: 0,
    maritalStatusName: ""
}
const initialState: IuserState = {
    user: userDetails,
    status: "idle",
    error: ""
}


const _authenticationService = new AuthenticationService(); 

export const getCurrentUserDetails = createAsyncThunk('user/fetchuser', async () => {
    try {

        const response = await _authenticationService.getUserDetails(); 
        return response?.data?.details;

    } catch (error: any) {
        return error.message
    }
});



const userSlice= createSlice({
    name: "user",
    initialState,
    reducers: {
        
    },
    extraReducers: (builder) => {
        builder
            .addCase(getCurrentUserDetails.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(getCurrentUserDetails.fulfilled, (state, action) => {
                state.status = 'idle';
                state.user = action.payload;
                
            })
            .addCase(getCurrentUserDetails.rejected, (state, action) => {
                // state.status = 'failed',
                // state.error = action.error.message as string;
            });

    }
   
})


export const selectCurrentUserDetails=(state:RootState)=> state.user.user;
export const RequestUserStatus=(state:RootState)=> state.user.status;
export const RequestUserError=(state:RootState)=> state.user.error;
export default userSlice.reducer;